import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.unshift.js";
import { detailColumns } from "./config/index";
import { BusinessApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  name: "businessdetail",
  // 定义属性
  data: function data() {
    return {
      columns: detailColumns,
      dataSource: [],
      loading: false,
      hisData: {},
      infoData: {},
      userTypeList: [{
        id: 1,
        name: "个人卖家"
      }, {
        id: 2,
        name: "入驻商家"
      }],
      levelList: [{
        id: 1,
        name: "等级一"
      }, {
        id: 2,
        name: "等级二"
      }, {
        id: 3,
        name: "等级三"
      }]
    };
  },
  mixins: [Mixin],
  // 方法集合
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      BusinessApi.busDetail({
        userId: this.$route.query.userId
      }).then(function (res) {
        _this.hisData = res.history;
        _this.infoData = res.info;
      });
    },
    getHisList: function getHisList() {
      var _this2 = this;
      BusinessApi.bushistoryData({
        userId: this.$route.query.userId
      }).then(function (res) {
        _this2.dataSource = res.list;
        var _this2$hisData = _this2.hisData,
          toalCheckPassNum = _this2$hisData.toalCheckPassNum,
          totalSalestotal = _this2$hisData.totalSalestotal,
          totalUnPerformanceNum = _this2$hisData.totalUnPerformanceNum,
          start_month = _this2$hisData.start_month,
          end_month = _this2$hisData.end_month;
        var Alldata = {
          all: "总计",
          checkpassnum: toalCheckPassNum,
          salestotal: totalSalestotal,
          unperformancenum: totalUnPerformanceNum,
          month: "".concat(start_month || '', " - ").concat(end_month || '')
        };
        console.log(_this2.hisData);
        console.log(Alldata);
        _this2.dataSource.unshift(Alldata);
      });
    }
  },
  components: {},
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created: function created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted: function mounted() {
    var _this3 = this;
    this.getDetail();
    setTimeout(function () {
      _this3.getHisList();
    }, 100);
  },
  beforeCreate: function beforeCreate() {},
  // 生命周期 - 创建之前
  beforeMount: function beforeMount() {},
  // 生命周期 - 挂载之前
  beforeUpdate: function beforeUpdate() {},
  // 生命周期 - 更新之前
  updated: function updated() {},
  // 生命周期 - 更新之后
  beforeDestroy: function beforeDestroy() {},
  // 生命周期 - 销毁之前
  destroyed: function destroyed() {},
  // 生命周期 - 销毁完成
  activated: function activated() {} // 如果页面有keep-alive缓存功能，这个函数会触发
};